
.hidden {
  visibility: hidden;
}
.authorization-bg .modal {
  @media only screen and (min-device-width: 1024px) {
    top: 18rem;
    .modal-authorization {
      align-items: start;
    }
  }
  @media (max-width: 767px) {
    top: 54px;
    .modal-authorization {
      align-items: start;
      margin-bottom: 80px;
    }
  }
}
